import React from 'react';
import { useController } from '../useController';
import { filtersInitalSate, filterActions } from './Implementation/filter.actions';
import { callOnlyTimes } from '../utils';
import { isfiltering, hasChanges } from './Filter.shared.utils';
import { FILTER_NAME } from './Implementation/filter.name';
import moment from 'moment';
import {
  EMPTY_FILTER,
  FILTER_NAME_BY_KEY,
  jobEmptyFilterValues,
  taskEmptyFilter,
  taskEmptyFilterValues,
} from './Implementation/Filter.empty';
export { FILTER_NAME } from './Implementation/filter.name';

export const FiltersContext = React.createContext(null);
export const useFiltersContext = () => React.useContext(FiltersContext);
export const useFilterContext = filterName => {
  const { filtersState, filtersController, isManager } = React.useContext(FiltersContext);
  const filterSetType = filtersState.getIn([filterName, 'filterSetType']);
  const isFiltering = filtersState.getIn([filterName, 'isFiltering']);
  const filterSets = filtersState.get(filterSetType);
  const isCollapsed = (section, defaultValue = false) => {
    const value = filtersState.getIn([filterName, 'collapsed', section]);
    return typeof value === 'undefined' ? defaultValue : value;
  };

  return {
    filterSetType,
    filterName,
    isManager,
    isFiltering,
    filterState: filtersState.get(filterName),
    filterController: filtersController[filterName],
    filterSets,
    isCollapsed,
  };
};

const WHITE_LIST = [
  'filterSetId',
  'myTasks',
  'communityIds',
  'taskNames',
  'assigneeAccounts',
  'inProgressStatus',
  'completedStatus',
  'notStartedStatus',
  'notReadyStatus',
  'dateConfirmation',
  'criticalTasks',
  'startDate',
  'finishDate',
  'duration',
  'status',
  'assigneeUserIds',
  'assigneeAccountIds',
  'task-assigned',
  'attachments',
  'update-request',
  'notes',
  'filter-sets',
];

// it will be like useEffect(() => {}, []) but this will execute immediately instead of waiting
// for components to be mounted. (onCompomentDidMount)
const once = callOnlyTimes(1);

const mapper = filter => {
  return filter.mapEntries(([field, value]) => {
    if (value === null) return null;
    if (field === 'isMyTasksOnly') {
      return ['myTasks', value];
    }
    if (field === 'taskTypes') {
      return ['taskNames', value.map(v => v.get('name'))];
    }
    if (field === 'community') {
      return ['communityIds', value.map(v => v.get('_id'))];
    }
    if (field === 'accountAssignee') {
      return ['assigneeAccounts', value.map(v => v.get('subAccountId'))];
    }
    if (!WHITE_LIST.includes(field)) return null;
    return [field, value];
  });
};

function normalizedFilterSetMapper(filterSet) {
  filterSet = filterSet.update('filter', mapper);
  if (filterSet.get('type') === 'jobSchedule') {
    filterSet = filterSet.update('filter', filter => jobEmptyFilterValues.merge(filter));
  } else if (filterSet.get('type') === 'taskList') {
    filterSet = filterSet.update('filter', filter => taskEmptyFilterValues.merge(filter));
  }
  return filterSet;
}

const statusFilterKeys = ['inProgressStatus', 'completedStatus', 'notStartedStatus', 'notReadyStatus'];

function noramlizedFiltersMapper([name, filter]) {
  const emptyFilter = FILTER_NAME_BY_KEY[name] || taskEmptyFilter;
  filter = emptyFilter.merge(filter).mapEntries(([field, value]) => {
    if (name !== 'jobs' && statusFilterKeys.includes(field)) return null;
    return [field, value];
  });
  return [name, mapper(filter)];
}

const parseDate = value => {
  const parsed = moment(value);
  if (!parsed.isValid()) return null;
  return parsed.toDate();
};
const parseDateField = value => {
  return value
    .update('dateEqual', parseDate)
    .update('dateFrom', parseDate)
    .update('dateTo', parseDate);
};

const updateDateFields = values => {
  if (values.has('startDate')) {
    values = values.update('startDate', parseDateField);
  } else {
    values = values.set('startDate', jobEmptyFilterValues.get('startDate'));
  }
  if (values.has('finishDate')) {
    values = values.update('finishDate', parseDateField);
  } else {
    values = values.set('finishDate', jobEmptyFilterValues.get('finishDate'));
  }
  if (values.has('type')) values = values.delete('type');
  if (!values.has('duration')) values = values.set('duration', jobEmptyFilterValues.get('duration'));
  if (!values.has('dateConfirmation')) values = values.set('dateConfirmation', 'all');
  if (!values.has('criticalTasks')) values = values.set('criticalTasks', 'all');
  return values;
};

const updateSavedFilterDates = (filters, filterName) => {
  const filter = filters.get(filterName);
  if (filterName !== FILTER_NAME.JOB) return filter;
  return updateDateFields(filter);
};

const withUserSettings = appState => {
  const settings = appState.getIn(['user', 'settings']);
  const savedFilterSets = settings.get('jobFilters').map(normalizedFilterSetMapper);
  const filters = settings.get('filters').mapEntries(noramlizedFiltersMapper);

  // restore saved Filter Sets
  const taskList = savedFilterSets.filter(filter => filter.get('type') === 'taskList');
  const jobSchedule = savedFilterSets
    .filter(filter => filter.get('type') === 'jobSchedule')
    .map(filter => filter.update('filter', updateDateFields)); // TODO: kind of confusing because `filter` should be named `values`

  // // restore saved filters
  return Object.entries(FILTER_NAME).reduce((state, [key, filterName]) => {
    if (!filters.has(filterName)) return state;
    // const savedFilter = filters.get(filterName); //removeAdvanedFilterProperties(filters, filterName);
    const savedFilter = updateSavedFilterDates(filters, filterName);
    const newValues = state.getIn([filterName, 'values']).merge(savedFilter);
    const filterSets = state.get(state.getIn([filterName, 'filterSetType'])); // [jobSchedule|taskList|null]
    const emptyValues = EMPTY_FILTER[key].get('values');

    return state
      .setIn([filterName, 'values'], newValues)
      .setIn([filterName, 'isFiltering'], isfiltering(emptyValues, newValues))
      .update(filterName, hasChanges(filterSets));
  }, filtersInitalSate.merge({ taskList, jobSchedule }));
};

export function createFiltersContext(appContext, filtersService) {
  const [filtersState, filtersController] = useController(
    filterActions,
    appContext.appState,
    appContext,
    withUserSettings
  );

  const isManager = appContext.appState.getIn(['user', 'role']) === 'manager';

  once(() => {
    filtersController.setFiltersService(filtersService);
  });

  return { filtersState, filtersController, isManager };
}
