import React from 'react';
import { SystemNotification } from '../SystemNotification';

export default ({ notification, controller }) => {
  const regexp = /^Hi, (.*), (.*)/;
  const regexpTaskName = /don\'t forget to give an update on the (.*) Task before the end of the day/;

  const parseNotification = message => {
    const result = (message || '').match(regexp);

    if (!result) return [null, null, message];

    const [, tradeName, rest] = result;
    const resultTaskName = (rest || '').match(regexpTaskName);

    if (!resultTaskName) return [tradeName, null, rest];
    const [, taskName] = resultTaskName;

    return [tradeName, taskName];
  };

  const [tradeName, taskName, message] = parseNotification(notification.get('message'));

  const FormatMessage = () => {
    if (!tradeName && !taskName) return message;
    if (!taskName)
      return (
        <>
          Hi,
          <span className="mx-1 text-dark cursor-default font-weight-bold text-nowrap text-truncate assignee">
            {tradeName}
          </span>
          {message}
        </>
      );

    // it has bot trade and task name.
    return (
      <>
        Hi,
        <span className="mx-1 text-dark cursor-default font-weight-bold text-nowrap text-truncate assignee">
          {tradeName}
        </span>
        don't forget to give an update on the
        <span className="text-dark"> {taskName} </span>
        before the end of the day.
      </>
    );
  };
  return (
    <SystemNotification
      key={notification.get('_id')}
      linkTo="/to-do"
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
    >
      <FormatMessage />
    </SystemNotification>
  );
};
