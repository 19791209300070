import React from 'react';
import { List, fromJS } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { markAsSync, markAsSideEffect } from '../../useController';
import { FilterSetsManagementModal } from '../FilterSets/FilterSetsManagementModal';
import { NewFilterSetNameModal } from '../FilterSets/NewFilterSetNameModal';
import { CustomDialog } from '../../Dialog/Dialog';
import { resetFilter, getActiveFilterset } from '../Filter.shared.utils';

export function filterSetActions(filterName, filterSetsAdapter, emptyFilter) {
  const filterSetsPath = emptyFilter.get('filterSetType');

  // TODO: deprecate this method.
  markAsSideEffect(readFilterSets);
  function readFilterSets() {
    // let _id = null;
    // const { emptyFilter } = filterSetsAdapter;
    // const jobFilter = localStorage.getItem(filterName);
    // if (jobFilter) _id = JSON.parse(jobFilter)?.filterSetId;

    filterSetsAdapter
      .readFilters({})
      .then(fromJS)
      .then(filterSets => {
        // const active = filterSets.find(f => f.get('_id') === _id);
        // saveFilterOnLocalStorage(filterName, active, emptyFilter);
        // this.events.emit('filter-sets:restore-active', active);
        // this.events.emit('filter-sets:changed', filterSets);
        // this.controller.dispatch([state => state.update(filterName, filter => filter.merge({ filterSets, active }))]);
        this.controller.dispatch([state => state.set(filterSetsPath, filterSets)]);
        // this.controller.dispatch([state => state.setIn([filterName, 'filterSets'], filterSets)]);
      });
  }

  markAsSync(selectFilter);
  function selectFilter(state, filterSet) {
    // const { filterName, emptyFilter } = filterSetsAdapter;
    // // this.events.emit('filter-sets:active', filter);
    // this.onActiveFilterSet(filter);
    // saveFilterOnLocalStorage(filterName, filter, emptyFilter);
    // return state.set('active', filter);

    this.controller[filterName].defer.saveFilterSettings();

    if (filterSet) {
      // copy filterSet values and `filterSet._id`
      const values = filterSet.get('filter').set('filterSetId', filterSet.get('_id'));
      return state.update(filterName, resetFilter(values));
      //state.setIn([filterName, 'values'], values);
    }

    // unselect filterSet, restore filter values to default.
    return state.update(filterName, resetFilter(emptyFilter.get('values'), false, false));
    //   filter =>
    //   filter.merge({
    //     isFiltering: false,
    //     hasChanges: false,
    //     values: emptyFilter.get('values'),
    //   })
    // );
  }

  // markAsSync(clearFilterSet);
  // function clearFilterSet(state) {
  //   const { filterName, emptyFilter } = filterSetsAdapter;
  //   // this.events.emit('filter-sets:active', filter);
  //   // this.onActiveFilterSet(null);
  //   saveFilterOnLocalStorage(filterName, null, emptyFilter);
  //   return state.set('active', null);
  // }

  // function saveFilterOnLocalStorage(filterName, filter, emptyFilter) {
  //   let persistedFilter = emptyFilter.toJS();

  //   if (filter) {
  //     persistedFilter = filter.get('filter').toJS();
  //     persistedFilter.filterSetId = filter.get('_id');
  //     persistedFilter.taskTypes = persistedFilter.taskTypes?.map(({ name }) => ({ name })) ?? [];
  //   }

  //   localStorage.setItem(filterName, JSON.stringify(persistedFilter));
  // }

  markAsSync(removeFilter);
  function removeFilter(state, filter) {
    return state.updateIn(filterSetsPath, fs => fs.filter(f => f !== filter));
    // return state.updateIn([filterName, 'filterSets'], fs => fs.filter(f => f !== filter));
  }

  // TODO: rename to `updateFilterSetName`
  markAsSync(updateFilterName);
  function updateFilterName(state, filter, name) {
    return state.update(filterSetsPath, fs => fs.map(f => (f === filter ? f.set('name', name) : f)));
    // return state.updateIn([filterName, 'filterSets'], fs => fs.map(f => (f === filter ? f.set('name', name) : f)));
  }

  // TODO: rename to updateFilterSetSharedState
  markAsSync(updateFilterSharedState);
  function updateFilterSharedState(state, index, isShared) {
    return state.setIn([filterSetsPath, index, 'isShared'], isShared);
    // return state.setIn([filterName, 'filterSets', index, 'isShared'], isShared);
  }

  markAsSideEffect(openChangeFilterOrderModal);
  async function openChangeFilterOrderModal() {
    // const activeId = this.state.getIn(['active', '_id']);
    const activeId = this.state.getIn([filterName, 'values', 'filterSetId']);
    const filterSets = this.state.get(filterSetsPath).toArray();
    // const filterSets = this.state.getIn([filterName, 'filterSets']).toArray();
    const { updateFilterSharedState } = this.controller[filterName].filterSets;
    const { addAlert, appController, appState, alert } = this;
    const { ordered } = await this.modal.open(FilterSetsManagementModal, {
      filterSets,
      addAlert,
      filtersService: filterSetsAdapter,
      appController,
      appState,
      alert,
      updateFilterSharedState,
    });

    if (!ordered) return;

    try {
      const newFilterSets = List(ordered)
        .filter(f => !f.get('toBeRemoved'))
        .map((f, index) => f.set('order', index + 1));
      // this.events.emit('filter-sets:changed', newFilterSets);
      const active = newFilterSets.find(fs => fs.get('_id') === activeId);

      const body = newFilterSets.toJS();
      await filterSetsAdapter.batchUpdateFilters(body);
      this.controller.dispatch([
        state => selectFilter.call(this, state.set(filterSetsPath, newFilterSets), active),
        // state => selectFilter.call(this, state.setIn([filterName, 'filterSets'], newFilterSets), active),
      ]);
      this.addAlert('Filter Sets successfully updated.');
    } catch (ex) {
      this.addAlert('There was a problem updating the Filter Sets. Please try again.', 'danger');
    }
  }

  const confirmSaveSharedFilter = {
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
        Filter Set Impact
      </>
    ),
    message: `This is a shared Filter Set. By confirming its changes, they will be applied to the Users and/or Custom Roles you shared this Filter Set with.`,
    titleAccept: 'Confirm Changes',
    titleCancel: 'Cancel',
  };

  markAsSideEffect(saveFilterSet);
  async function saveFilterSet() {
    // const activeFilterSetId = this.state.getIn([filterName, 'values', 'filterSetId']);
    // if (!activeFilterSetId) return; // there is no active filter into which save the settings.

    const filterState = this.state.get(filterName);
    const filterSetType = filterState.get('filterSetType');
    const filterSets = this.state.get(filterSetType);
    // const active = getActiveFiltersetNEW(this.state, filterSetsPath, filterName);
    const active = getActiveFilterset({ filterState, filterSets });
    // this.state.getIn([filterName, 'filterSets']).find(fs => fs.get('_id') === activeFilterSetId);
    if (!active) return; // there is no active filter into which save the settings.

    if (active.get('isShared')) {
      const { isAccept } = await this.modal.open(CustomDialog, confirmSaveSharedFilter);
      if (!isAccept) return;
    }

    // NOTE: this field should be `filterSetId` insted (but BE thinks different)
    const { type, _id: filterId } = active.toJS();
    const filter = this.state
      .getIn([filterName, 'values']) //pull filter from state
      // .update('taskTypes', types => types.map(t => Map({ name: t.get('name') })))
      // .update('taskTypes', types => types.map(({ name }) => ({ name })))
      .toJS(); // remove unwanted fields from taskType

    // TODO: deprecate saving filters on local storage
    // localStorage.setItem(filterName, JSON.stringify(filter));

    try {
      await filterSetsAdapter.updateFilter({ type, filter }, { params: { filterId } });
      this.addAlert('Filter Set successfully updated.');
    } catch (ex) {
      this.addAlert('There was a problem updating this Filter Set. Please try again.', 'danger');
      throw ex;
    }

    this.controller.dispatch([
      state => {
        const filterSet = active.set('filter', fromJS(filter));
        const index = state.get(filterSetsPath).indexOf(active);
        // const index = state.getIn([filterName, 'filterSets']).indexOf(active);
        // this.events.emit('filter-sets:active', filterSet);
        // this.onActiveFilterSet(filterSet);
        // return state.setIn([filterName, 'filterSets', index], filterSet).setIn([filterName, 'hasChanges'], false);
        return state.setIn([filterSetsPath, index], filterSet).setIn([filterName, 'hasChanges'], false);
      },
    ]);
  }

  markAsSideEffect(saveNewFilterSet);
  async function saveNewFilterSet() {
    const { state } = this;
    const { filterSetName: name } = await this.modal.open(NewFilterSetNameModal, {
      filterSets: state.get(filterSetsPath),
      // filterSets: state.getIn([filterName, 'filterSets']),
    });

    if (!name) return; // it was canceled

    const filter = state
      //pull filter from state
      .getIn([filterName, 'values'])
      // remove unwanted fields from taskType
      // .update('taskTypes', types => types.map(t => Map({ name: t.get('name') })))
      // .update('taskTypes', types => types.map(type => ({ name: type.get('name') })))
      .toJS();

    // const filter = filterSet.update('taskTypes', types => types.map(type => ({ name: type.get('name') }))).toJS();

    filterSetsAdapter
      .createFilter({ name, filter })
      .then(fromJS)
      .then(filterSet => {
        this.addAlert('Filter Set successfully created.');
        this.controller.dispatch([
          state =>
            state
              .update(filterSetsPath, fs => {
                const sanitizedFilterSet = sanitizeFilterSet(filterSet, filter);
                return fs.push(sanitizedFilterSet);
              })
              // .updateIn([filterName, 'filterSets'], fs => fs.push(filterSet))
              .setIn([filterName, 'values', 'filterSetId'], filterSet.get('_id'))
              .setIn([filterName, 'hasChanges'], false),
          // const newFilterSets = fs.push(filterSet);
          // this.events.emit('filter-sets:changed', newFilterSets);
          //   return newFilterSets;
          // })
          // .set('active', filterSet),
        ]);
        // this.events.emit('filter-sets:active', filterSet);
        // TODO deprecate this event!
        // this.onActiveFilterSet(filterSet);
        // TODO: deprecate this code, prefer BE/user/profile persistence
        // const filter = filterSet
        //   .get('filter')
        //   .set('filterSetId', filterSet.get('_id'))
        //   .toJS();
        // localStorage.setItem(filterName, JSON.stringify(filter));
      })
      .catch(err => {
        this.addAlert('There was a problem creating this Filter Set. Please try again.', 'danger');
      });
  }
  return {
    readFilterSets,
    selectFilter,
    // clearFilterSet,
    // saveFilterOnLocalStorage,
    removeFilter,
    updateFilterName,
    updateFilterSharedState,
    openChangeFilterOrderModal,
    saveFilterSet,
    saveNewFilterSet,
  };
}

const sanitizeFilterSet = (filterSet, filter) => {
  const allowedKeys = Object.keys(filter);
  return filterSet.update('filter', originalFilter => originalFilter.filter((value, key) => allowedKeys.includes(key)));
};
