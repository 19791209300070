import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

const TIME_OPTIONS = [
  '6 am',
  '7 am',
  '8 am',
  '9 am',
  '10 am',
  '11 am',
  '12 pm',
  '1 pm',
  '2 pm',
  '3 pm',
  '4 pm',
  '5 pm',
  '6 pm',
  '7 pm',
];

const DropdownMenu = styled(Dropdown.Menu)`
  max-height: 15rem;
  min-width: 5rem;

  .dropdown-item {
    height: 3rem;
    width: 5rem;
    display: flex;
    align-items: center;
    padding: 1rem;
  }
`;

const DropdownToggle = styled(Dropdown.Toggle)`
  &.form-control:disabled {
    background-color: #f9f9fa;
    color: #c1c6cf;
  }
`;

const getTimeOptions = event => {
  switch (event) {
    case 'end-of-day-reminder':
    case 'task-missed-check-out':
    case 'day-wrap-up':
      return TIME_OPTIONS.slice(5, 14);
    default:
      return TIME_OPTIONS;
  }
};

export default function({ disabled, selected, onSelect, event, ...props }) {
  const options = props.options || getTimeOptions(event);

  return (
    <Dropdown>
      <DropdownToggle childBsPrefix="form-control form-control-sm" disabled={disabled}>
        {selected}
      </DropdownToggle>
      <DropdownMenu bsPrefix="dropdown-menu overflow-auto" style={{ maxHeight: '15rem' }}>
        {options.map((option, index) => (
          <Dropdown.Item key={index} eventKey={option} onSelect={option => onSelect(option)}>
            {option}
          </Dropdown.Item>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
